// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).ready(function() {
    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('reset_password_token')) {
        let token = urlParams.get('reset_password_token')
        $.ajax({
            url: `/validate_token?reset_password_token=${token}`,
            type: 'GET',
            dataType: 'json',
            success: function() {},
            error: function() {alert('Reset password token is not valid.'); window.location.href = '/';}
        })
    }
})

$(document).on('click', '#login_button', function () {
    if (loginIsValid()) {
        let form = $('#login_form');
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            dataType: 'json',
            data: form.serialize(),
            success: function (server_return) {
                if (server_return.status === 'failed') {
                    swal("Authentication Failed", "The email address and password entered is incorrect, please try again.", "error");
                } else if (server_return.status === 'success') {
                    if (server_return.user_type === "moderator") {
                        window.location.href = '/moderator/dashboard'
                    } else {
                        window.location.href = '/enter_otp';
                    }
                }
            },
            error: function (xhr, err) {
                swal("Error", "The server is unable to process the request, please try again later.", "error");
            }
        });
    }
});

$(document).on('click', '#validation_otp_button', function () {
    if (otpIsValid()) {
        let form = $('#otp_form');
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            dataType: 'json',
            data: form.serialize(),
            success: function (server_return) {
                if (server_return.status === 'failed') {
                    swal("OTP Incorrect", "The OTP entered is incorrect, please try again.", "error");
                } else if (server_return.status === 'success') {
                    window.location.href = '/dashboard';
                }
            },
            error: function (xhr, err) {
                swal("Error", "The server is unable to process the request, please try again later.", "error");
            }
        });
    }
});

$(document).on('click', '#set_new_password_button', function () {
    if (newPasswordValid()) {
        let form = $('#new_password_form');
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            dataType: 'json',
            data: form.serialize(),
            success: function (server_return) {
                if (server_return.status === 200) {
                    alert('Your password has successfully been reset.')
                    window.location.href = '/'
                } else {
                    alert('There was an error resetting your password. Please try again later.')
                }
            },
            error: function (xhr, err) {
                alert('Error: There was an error resetting your password. Please try again later.')
            }
        });
    }
});

$(document).on('click', '#request_new_otp_button', function() {
    $.get('/request_new_otp', function(server_return) {
        if (server_return.status === 'success')
            swal("OTP Requested", "A new OTP has been sent.", "success");
        else
            swal("Error", "Unable to send new OTP, please try again later.", "error");
    });
});

function loginIsValid() {
    let is_valid = true;
    let email = $('#email_address_input');
    let password = $('#password_input');

    if (email.val() === '') {
        email.addClass('error');
        is_valid = false;
    } else {
        email.removeClass('error');
    }

    if (password.val() === '') {
        password.addClass('error');
        is_valid = false;
    } else {
        password.removeClass('error');
    }

    return is_valid;
}

function otpIsValid() {
    let is_valid = true;
    let otp = $('#email_address_input');

    if (otp.val() === '') {
        otp.addClass('error');
        is_valid = false;
    } else {
        otp.removeClass('error');
    }

    return is_valid;
}

function newPasswordValid() {
    let is_valid = false;
    let new_pass = $('#new_password_password');
    let confirm_new_pass = $('#new_password_confirm_password');

    if (new_pass.val().length < 8) {
        new_pass.addClass('error');
        confirm_new_pass.addClass('error');
        is_valid = false;
        alert('New password may not be shorter than 8 characters!');
    } else if (new_pass.val() !== confirm_new_pass.val()) {
        new_pass.addClass('error');
        confirm_new_pass.addClass('error');
        is_valid = false;
        alert('Your passwords do not match!')
    }else{
        new_pass.removeClass('error');
        confirm_new_pass.removeClass('error');
        is_valid = true;
    }

    return is_valid;
}

